import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Before you begin`}</h3>
    <ul>
      <li parentName="ul">{`You'll need a account on the smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=ssh"
        }}><strong parentName="a">{`Register here`}</strong></a></li>
      <li parentName="ul">{`You'll need the `}<strong parentName="li">{`enrollment token`}</strong>{` you received upon signup.`}</li>
      <li parentName="ul">{`We support `}<inlineCode parentName="li">{`amd64`}</inlineCode>{` and `}<inlineCode parentName="li">{`arm64`}</inlineCode>{` architectures`}</li>
      <li parentName="ul">{`We support the following host platforms:`}
        <ul parentName="li">
          <li parentName="ul">{`RHEL 8`}</li>
          <li parentName="ul">{`CentOS 8`}</li>
          <li parentName="ul">{`CentOS 7`}</li>
          <li parentName="ul">{`Amazon Linux 2`}</li>
          <li parentName="ul">{`Ubuntu 20.04 LTS (Focal)`}</li>
          <li parentName="ul">{`Ubuntu 18.04 LTS (Bionic)`}</li>
          <li parentName="ul">{`Ubuntu 16.04 LTS (Xenial)`}</li>
          <li parentName="ul">{`Debian 10 (Buster)`}</li>
          <li parentName="ul">{`Debian 9 (Stretch)`}</li>
          <li parentName="ul">{`Debian 8 (Jessie)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Running this quickstart will modify config files related to `}<inlineCode parentName="li">{`systemd`}</inlineCode>{`, PAM, NSS, and SSHD:`}
        <ul parentName="li">
          <li parentName="ul">{`We add `}<inlineCode parentName="li">{`step-ssh-renew`}</inlineCode>{` and `}<inlineCode parentName="li">{`step-ssh-metadata`}</inlineCode>{` services to `}<inlineCode parentName="li">{`systemd`}</inlineCode>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`step-ssh-renew`}</inlineCode>{` rotates the SSH host certificate every eight hours.`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`step-ssh-metadata`}</inlineCode>{` syncs user ACLs with your CA every 3 seconds.`}</li>
            </ul>
          </li>
          <li parentName="ul">{`And we modify the following files:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`/etc/pam.d/sshd`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`/etc/pam.d/sudo`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`/etc/pam.d/su`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`/etc/ssh/sshd_config`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`/etc/nsswitch.conf`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul">{`We suggest backing these up before you begin setup on a particular base machine type, so that you can quickly revert changes if needed.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Understanding Host Tags`}</h2>
    <p><strong parentName="p">{`This section only applies to multi-user environments.`}</strong></p>
    <p><strong parentName="p">{`Host Tags`}</strong>{` (key-value pairs) are the pillar of our access control model.
Rather than mapping people or groups directly to hosts, you'll map `}<em parentName="p">{`tag combinations`}</em>{` to your hosts and to your user groups. o
First you'll put your hosts into logical groups using tags, eg. `}<inlineCode parentName="p">{`role`}</inlineCode>{`:`}<inlineCode parentName="p">{`web`}</inlineCode>{` or `}<inlineCode parentName="p">{`env`}</inlineCode>{`:`}<inlineCode parentName="p">{`staging`}</inlineCode>{`.
Then, you'll grant user groups access to all hosts with a specific tag combination.
Finally, you'll choose which user group tag combinations will allow `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` privileges on any matching hosts.`}</p>
    <p>{`Let's look at an example:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/host-tags-example.png",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`developers`}</inlineCode>{` group will have access to  `}<inlineCode parentName="li">{`myserver #1`}</inlineCode>{` only.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`data`}</inlineCode>{` group will have access to  `}<inlineCode parentName="li">{`myserver #2`}</inlineCode>{` and  `}<inlineCode parentName="li">{`myserver #3`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`ops`}</inlineCode>{` group will have `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` access to `}<inlineCode parentName="li">{`myserver #2`}</inlineCode>{` and `}<inlineCode parentName="li">{`myserver #3`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Of course, hosts and groups can have as many tag combinations as you like.
Take a minute to think about how you'd like to use Host Tags in your environment.`}</p>
    <h2>{`Quick Installation Instructions`}</h2>
    <p>{`Our installation script will guide you through host setup. It will:`}</p>
    <ul>
      <li parentName="ul">{`Install the `}<inlineCode parentName="li">{`step`}</inlineCode>{` CLI and `}<inlineCode parentName="li">{`step-ssh`}</inlineCode>{` utilities`}</li>
      <li parentName="ul">{`Comment out the broken `}<inlineCode parentName="li">{`pam_systemd.so`}</inlineCode>{` in `}<inlineCode parentName="li">{`common-session`}</inlineCode>{`,
which causes a delay at login
(this is a known Ubuntu/Debian bug)`}</li>
      <li parentName="ul">{`Bootstrap your host to your CA`}</li>
      <li parentName="ul">{`Enroll your host with an SSH host certificate`}</li>
      <li parentName="ul">{`Configure SSHD to use certificate authentication`}</li>
      <li parentName="ul">{`Activate `}<inlineCode parentName="li">{`step-ssh`}</inlineCode>{` PAM/NSS modules`}</li>
      <li parentName="ul">{`Add the host to the CA's inventory`}</li>
    </ul>
    <h3>{`Step 1. Install Smallstep SSH`}</h3>
    <h4>{`If your host is a bastion host`}</h4>
    <p>{`As `}<inlineCode parentName="p">{`root`}</inlineCode>{`, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ curl -sSLO https://files.smallstep.com/ssh-host.sh
$ bash ssh-host.sh --is-bastion
`}</code></pre>
    <h4>{`If your host is `}<em parentName="h4">{`behind`}</em>{` a bastion host`}</h4>
    <p>{`As `}<inlineCode parentName="p">{`root`}</inlineCode>{`, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ curl -sSLO https://files.smallstep.com/ssh-host.sh
$ bash ssh-host.sh --bastion=<bastion hostname>
`}</code></pre>
    <h4>{`For any other host`}</h4>
    <p>{`As `}<inlineCode parentName="p">{`root`}</inlineCode>{`, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl -sSf https://files.smallstep.com/ssh-host.sh | bash
`}</code></pre>
    <p>{`You'll be prompted for
your team ID,
enrollment token,
hostname,
and a list of space-separated host tags
(eg. "`}<inlineCode parentName="p">{`db=dev region=us-west-1`}</inlineCode>{`").`}</p>
    <h4>{`Passing flags to `}<inlineCode parentName="h4">{`ssh-host.sh`}</inlineCode></h4>
    <p>{`The `}<inlineCode parentName="p">{`ssh-host.sh`}</inlineCode>{` utility can also be run non-interactively,
as long as you provide the following `}<em parentName="p">{`required flags`}</em>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`--team "[your team ID]"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`--token "[your enrollment token]"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`--hostname "[the hostname]"`}</inlineCode></li>
      <li parentName="ul">{`One or more `}<inlineCode parentName="li">{`--tag "key=value"`}</inlineCode>{` to assign `}<a parentName="li" {...{
          "href": "#understanding-host-tags"
        }}>{`host tags`}</a></li>
    </ul>
    <p>{`The following additional flags are available:`}</p>
    <ul>
      <li parentName="ul">{`Any number of `}<inlineCode parentName="li">{`--principal "[hostname or IP]"`}</inlineCode>{`,
to add additional hostnames or IPs to the host certificate.
Add all hostnames or IPs that people will use to access the host.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--is-bastion`}</inlineCode>{` to indicate a bastion host`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`--bastion "[bastion hostname]"`}</inlineCode>{` to specify the bastion that sits in front of this host`}</li>
    </ul>
    <h3>{`Step 2. Test your installation`}</h3>
    <p>{`Before you sign out of your `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` session, test your installation by logging in and running `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` in a separate session.`}</p>
    <p><strong parentName="p">{`This step is especially important if you have made any non-standard changes to your PAM or NSS stacks.`}</strong></p>
    <p>{`Now sign in at `}<inlineCode parentName="p">{`https://smallstep.com/app/[Team ID]`}</inlineCode></p>
    <p>{`You should see your host listed under the "`}<strong parentName="p">{`Hosts`}</strong>{`" tab.`}</p>
    <h2>{`Manual Instructions`}</h2>
    <p>{`If you wish to enroll your host without using our script,
you can follow our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/ssh/hosts-step-by-step"
      }}>{`step-by-step installation instructions`}</a>{` instead.`}</p>
    <h2>{`Need to remove a host?`}</h2>
    <p>{`As `}<inlineCode parentName="p">{`root`}</inlineCode>{` on the host, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`curl -sSf https://files.smallstep.com/ssh-host-uninstall.sh | bash
`}</code></pre>
    <p>{`This script will:`}</p>
    <ul>
      <li parentName="ul">{`Unregister the host from the host inventory on the CA`}</li>
      <li parentName="ul">{`Deactivate Smallstep SSH`}</li>
      <li parentName="ul">{`Revert SSHD configuration changes`}</li>
      <li parentName="ul">{`Remove your `}<inlineCode parentName="li">{`$HOME/.step`}</inlineCode>{` configuration directory`}</li>
      <li parentName="ul">{`Remove the `}<inlineCode parentName="li">{`step-ssh`}</inlineCode>{` package`}</li>
    </ul>
    <h2>{`Optional Hardening`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Now that you're using certificates, you may wish to explicitly disallow the use of `}<inlineCode parentName="p">{`authorized_keys`}</inlineCode>{` files on the host.`}</p>
        <p parentName="li">{`You can do this by setting `}<inlineCode parentName="p">{`AuthorizedKeysFile none`}</inlineCode>{` in `}<inlineCode parentName="p">{`sshd_config`}</inlineCode>{`. You may want to allow `}<inlineCode parentName="p">{`authorized_keys`}</inlineCode>{` for an emergency access account, however. A configuration for this might look like:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`Match User *,!ubuntu
    AuthorizedKeysFile none
`}</code></pre>
    <h2>{`Troubleshooting Tips`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Having trouble? You should be able to revert any changes by running `}<inlineCode parentName="p">{`step-ssh deactivate`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Suspect host or user certificates are not working? In other words, your ssh client fails to log in or shows "trust on first use" warning? Try this:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Be sure you can connect to our test server, with `}<inlineCode parentName="li">{`ssh-test.app.smallstep.com`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`On your client run `}<inlineCode parentName="li">{`step ssh list`}</inlineCode>{` and find your user certificate marked `}<inlineCode parentName="li">{`(ECDSA-CERT)`}</inlineCode>{`, e.g. `}<inlineCode parentName="li">{`256 SHA256:Bb2TcimUYj8Nc5w4FhpZ/gmeNIIvLIzphTx35NzaRoA alice@smallstep.com (ECDSA-CERT)`}</inlineCode>{`, which you can inspect with `}<inlineCode parentName="li">{`step ssh list --raw alice@smallstep.com | step ssh inspect`}</inlineCode>{`. Be sure the current time is neither before or after the period specified in `}<inlineCode parentName="li">{`Valid:`}</inlineCode>{` .`}</li>
          <li parentName="ul">{`On the destination host run `}<inlineCode parentName="li">{`step ssh inspect /etc/ssh/ssh_host_ecdsa_key-cert.pub`}</inlineCode>{`. Make sure the current time is within the period of `}<inlineCode parentName="li">{`Valid:`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`The destination host's `}<inlineCode parentName="li">{`sshd_config`}</inlineCode>{` should show included the following lines for `}<inlineCode parentName="li">{`sshd`}</inlineCode>{` (make sure the service has reloaded its config) to leverage certificate-based authentication:`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell-session",
            "metastring": "nocopy",
            "nocopy": true
          }}>{`$ tail -n 7 /etc/ssh/sshd_config
#       ForceCommand cvs server

# autogenerated by step @ 2020-04-02T21:16:05Z
TrustedUserCAKeys /etc/ssh/ca.pub
HostCertificate /etc/ssh/ssh_host_ecdsa_key-cert.pub
HostKey /etc/ssh/ssh_host_ecdsa_key
# end
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`You can run `}<inlineCode parentName="li">{`sshd -t`}</inlineCode>{` (as root) to test you SSHD configuration. No output means the file is OK; you'll get an error if any of the referenced files (eg. host keys) are not accessible to `}<inlineCode parentName="li">{`sshd`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Suspect your ssh client might have a problem? Use verbose logging `}<inlineCode parentName="p">{`ssh -v <hostname>`}</inlineCode>{`. Look out for following key lines/events below in your log. If you don't see errors the absence of these events might indicate your config is invalid / not being applied.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Your team's config is applied for this specific host:`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`debug1: Executing command: 'step ssh check-host ec2-52-200-74-193.compute-1.amazonaws.com'
debug1: Reading configuration data /etc/ssh/ssh_config
debug1: /etc/ssh/ssh_config line 48: Applying options for *
debug1: /etc/ssh/ssh_config line 52: Applying options for *
debug1: Executing proxy command: exec step ssh proxycommand --provisioner "okta" alice ec2-52-200-74-193.compute-1.amazonaws.com 22
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The host certificate passed authentication on the client:`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`debug1: Server host certificate: ecdsa-sha2-nistp256-cert-v01@openssh.com SHA256:ON/csaCQ4yDKfxblQnOLTiZIOoOtsHPAjQn6DrW+k9I, serial 2468062085372141930 ID "ec2-52-200-74-193.compute-1.ama
debug1: Server host certificate: ecdsa-sha2-nistp256-cert-v01@openssh.com SHA256:46gC0CEzXWN4acTHGQldL6H+QlbhB4+KPZjkoRToI/w, serial 8551898981883739717 ID "ec2-52-200-74-193.compute-1.amazonaws.com" CA ecdsa-sha2-nistp256 SHA256:sqfZG6AOPUvcheFUIZDX+DEesnyfNZQ5JwqpcxUzY+0 valid from 2020-04-14T04:45:10 to 2020-05-14T04:46:10
debug1: Host 'ec2-52-200-74-193.compute-1.amazonaws.com' is known and matches the ECDSA-CERT host certificate.
debug1: Found CA key in /Users/alice/.step/ssh/known_hosts:1
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The username being used for authentication:`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`debug1: Authenticating to ec2-52-200-74-193.compute-1.amazonaws.com:22 as 'alice'
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`The ssh client offers the user's certificate to the destination host:`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`debug1: Offering public key: sebastian@smallstep.com ECDSA-CERT SHA256:VIa1uWhBTjjtpW3IBkUG/aFGfqlUhjkXNQVk6Hc1lXc agent
debug1: Server accepts key: alice@smallstep.com ECDSA-CERT SHA256:VIa1uWhBTjjtpW3IBkUG/aFGfqlUhjkXNQVk6Hc1lXc agent
debug1: sign_and_send_pubkey: no separate private key for certificate "alice@smallstep.com"
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The following endpoints are being used to deliver the SSH service`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ssh-test.app.smallstep.com`}</inlineCode>{` — For SSH test sessions`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`https://ssh.<team-name>.ca.smallstep.com`}</inlineCode>{` — The CA internal PKI APIs (protected by mTLS)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`https://smallstep.com/app/teams/sso/success`}</inlineCode>{` — Single sign-on success page`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`https://smallstep.com/app/<team-name>`}</inlineCode>{` — Admin Dashboard`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`https://api.smallstep.com`}</inlineCode>{` — APIs to fetch team information`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`https://auth.smallstep.com`}</inlineCode>{` — OpenID Connect flow, if you have no identity provider configured`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      